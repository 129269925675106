export default {
  contoh: [
    {
      type: 'selection',
      instruction: 'Pada persoalan berikutnya terdapat sejumlah pertanyaan mengenai kata-kata yang telah saudara hafalkan tadi. pilihlah jawaban sodara pada pilihan dibawah soal.',
      questions: [
        {
          number: 1,
          title: 'Contoh 09',
          question: 'Kata yang mempunyai huruf permulaan - Q - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          },
          answers: {
            hint: 'Quintet adalah termasuk dalam jenis kesenian. sehingga jawaban yang benar adalah ',
            answer: 'Kesenian',
            message: 'Oleh karena itu, pada pilihan contoh 09, jawaban "Quintet" yang harus dipilih.'
          }
        },
        {
          number: 2,
          title: 'Contoh berikutnya',
          question: 'Kata yang mempunyai huruf permulaan - Z - suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          },
          answers: {
            hint: 'Jawabannya adalah e, karena Zebra termasuk dalam jenis ',
            answer: 'Binatang',
            message: ''
          }
        }
      ],
      timer: 120
    }
  ],

  ingat: [
    {
      timer: 180,
      instruction: 'Disediakan waktu 3 menit untuk menghafalkan kata-kata dibawah ini.',
      remembers: [
        {
          title: 'Bunga',
          remember: ['Soka', 'Larat', 'Flamboyan', 'Yasmin', 'Dahlia']
        },
        {
          title: 'Perkakas',
          remember: ['Wajan', 'Jarum', 'Kikir', 'Cangkul', 'Palu']
        },
        {
          title: 'Burung',
          remember: ['Itik', 'Elang', 'Walet', 'Tekukur', 'Nuri']
        },
        {
          title: 'Kesenian',
          remember: ['Quintet', 'Arca', 'Opera', 'Gamelan', 'Ukiran']
        },
        {
          title: 'Binatang',
          remember: ['Musang', 'Rusa', 'Beruang', 'Zebra', 'Harimau']
        }
      ]
    }
  ],

  soal: [
    {
      timer: 360,
      questions: [
        {
          number: 157,
          question: 'Kata yang mempunyai huruf permulaan - A - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 158,
          question: 'Kata yang mempunyai huruf permulaan - B - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 159,
          question: 'Kata yang mempunyai huruf permulaan - C - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 160,
          question: 'Kata yang mempunyai huruf permulaan - D - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 161,
          question: 'Kata yang mempunyai huruf permulaan - E - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 162,
          question: 'Kata yang mempunyai huruf permulaan - F - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 163,
          question: 'Kata yang mempunyai huruf permulaan - G - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 164,
          question: 'Kata yang mempunyai huruf permulaan - H - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 165,
          question: 'Kata yang mempunyai huruf permulaan - I - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 166,
          question: 'Kata yang mempunyai huruf permulaan - J - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 167,
          question: 'Kata yang mempunyai huruf permulaan - K - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 168,
          question: 'Kata yang mempunyai huruf permulaan - L - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 169,
          question: 'Kata yang mempunyai huruf permulaan - M - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 170,
          question: 'Kata yang mempunyai huruf permulaan - N - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 171,
          question: 'Kata yang mempunyai huruf permulaan - O - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 172,
          question: 'Kata yang mempunyai huruf permulaan - P - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 173,
          question: 'Kata yang mempunyai huruf permulaan - R - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 174,
          question: 'Kata yang mempunyai huruf permulaan - S - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 175,
          question: 'Kata yang mempunyai huruf permulaan - T - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        },
        {
          number: 176,
          question: 'Kata yang mempunyai huruf permulaan - U - adalah suatu .........',
          question_marks: {
            a: 'Bunga',
            b: 'Perkakas',
            c: 'Burung',
            d: 'Kesenian',
            e: 'Binatang'
          }
        }
      ]
    }
  ]

}
