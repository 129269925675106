export default {
  contoh: [
    {
      type: 'image',
      instruction: 'Ditentukan 5 (lima) buah kubus, yaitu kubus a, b, c, d, e. Pada tiap-tiap kubus terdapat enam tanda yang berlainan pada setiap sisinya. Tiga dari tanda itu dapat dilihat.\nKubus-kubus yang ditentukan itu (a, b, c, d, e) ialah kubus-kubus yang berbeda, artinya kubus-kubus itu dapat mempunyai tanda-tanda yang sama, akan tetapi susunannya berlainan. Setiap soal memperlihatkan salah satu kubus yang ditentukan di dalam kedudukan yang berbeda.\n\nCarilah kubus yang dimaksudkan itu dan pilih jawaban saudara pada pilihan dibawah soal.\nKubus itu dapat diputar, dapat digulingkan atau dapat diputar dan digulingkan dalam pikiran saudara.\nOleh karena itu mungkin akan terlihat suatu tanda baru.',
      questions: [
        {
          number: 1,
          title: 'Contoh 08',
          question: '08_contoh_1',
          question_marks: {
            a: '08_contoh_soal_a',
            b: '08_contoh_soal_b',
            c: '08_contoh_soal_c',
            d: '08_contoh_soal_d',
            e: '08_contoh_soal_e'
          },
          answers: {
            hint: 'Contoh ini memperlihatkan kubus a dengan kedudukan yang berbeda.\nMendapatkannya adalah dengan cara menggulingkan lebih dahulu kubus itu ke kiri satu kali dan kemudian diputar ke kiri satu kali, sehingga sisi kubus yang bertanda dua segiempat hitam terletak di depan, seperti kubus a.',
            answer: 'a',
            message: 'Oleh karena itu, pada pilihan contoh ini, gambar a yang harus dipilih.'
          }
        },
        {
          number: 2,
          title: 'Contoh berikutnya',
          question: '08_contoh_2',
          question_marks: {
            a: '08_contoh_soal_a',
            b: '08_contoh_soal_b',
            c: '08_contoh_soal_c',
            d: '08_contoh_soal_d',
            e: '08_contoh_soal_e'
          },
          answers: {
            hint: 'Contoh kedua adalah kubus e.\nCara mendapatkannya adalah dengan digulingkan ke belakang satu kali dan diputar ke kanan satu kali, sehingga sis kubus yang bertanda dua segiempat hitam terletak di depan, seperti kubus e.',
            answer: 'e',
            message: 'Oleh karena itu, pada pilihan contoh ini, gambar e yang harus dipilih.'
          }
        },
        {
          number: 3,
          title: 'Contoh berikutnya',
          question: '08_contoh_3',
          question_marks: {
            a: '08_contoh_soal_a',
            b: '08_contoh_soal_b',
            c: '08_contoh_soal_c',
            d: '08_contoh_soal_d',
            e: '08_contoh_soal_e'
          },
          answers: {
            hint: 'Contoh ketiga adalah kubus b.\nCara mendapatkannya adalah dengan menggulingkannya ke kiri satu kali, sehingga dasar kubus yang tadinya tidak terlihat memunculkan tanda baru (dalam hal ini adalah tanda dua segiempat hitam) dan tanda silang pada sisi atas kubus itu menjadi tidak terlihat lagi.',
            answer: 'b',
            message: 'Oleh karena itu, pada pilihan contoh ini, gambar b yang harus dipilih.'
          }
        },
        {
          number: 4,
          title: 'Contoh berikutnya',
          question: '08_contoh_4',
          question_marks: {
            a: '08_contoh_soal_a',
            b: '08_contoh_soal_b',
            c: '08_contoh_soal_c',
            d: '08_contoh_soal_d',
            e: '08_contoh_soal_e'
          },
          answers: {
            hint: '',
            answer: 'c',
            message: 'Pada pilihan contoh ini, gambar c yang harus dipilih.'
          }
        },
        {
          number: 5,
          title: 'Contoh berikutnya',
          question: '08_contoh_5',
          question_marks: {
            a: '08_contoh_soal_a',
            b: '08_contoh_soal_b',
            c: '08_contoh_soal_c',
            d: '08_contoh_soal_d',
            e: '08_contoh_soal_e'
          },
          answers: {
            hint: '',
            answer: 'd',
            message: 'Pada pilihan contoh ini, gambar d yang harus dipilih.'
          }
        }
      ],
      timer: 180
    }
  ],

  soal: [
    {
      timer: 540,
      questions: [
        {
          number: 137,
          question: '08_soal_137',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 138,
          question: '08_soal_138',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 139,
          question: '08_soal_139',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 140,
          question: '08_soal_140',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 141,
          question: '08_soal_141',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 142,
          question: '08_soal_142',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 143,
          question: '08_soal_143',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 144,
          question: '08_soal_144',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 145,
          question: '08_soal_145',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 146,
          question: '08_soal_146',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 147,
          question: '08_soal_147',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 148,
          question: '08_soal_148',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 149,
          question: '08_soal_149',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 150,
          question: '08_soal_150',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 151,
          question: '08_soal_151',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 152,
          question: '08_soal_152',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 153,
          question: '08_soal_153',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 154,
          question: '08_soal_154',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 155,
          question: '08_soal_155',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        },
        {
          number: 156,
          question: '08_soal_156',
          question_marks: {
            A: '08_soal_a',
            B: '08_soal_b',
            C: '08_soal_c',
            D: '08_soal_d',
            E: '08_soal_e'
          }
        }
      ]
    }
  ]

}
