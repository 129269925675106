<template>
  <div class="">
    <Header/>
    <Timer v-show="paulitime" />
    <div class="" style="margin: 3px" v-show="!paulitime" >
      <v-container>
      <v-card outlined shaped class="mx-auto" max-width="300" >
      <v-card-title class="pb-0">
      <p class="mx-auto font-weight-thin" v-show="!habis">Menyiapkan test Pauli.</p>
      <p class="mx-auto font-weight-thin" v-show="habis">Waktu test Pauli habis.</p>
      </v-card-title>
      </v-card>
      </v-container>
    </div>
    <form v-if="show">
    <v-card class="scroll px-4" outlined max-height="1000" max-width="1900" v-show="isFullScreen">
      <v-row>
        <v-col v-for="(a, y) in soalPauli" :key="y">
          <div v-for="(i, x) in a" :key="x">
            <span class="py-1 font10">{{ i }}</span>
            <div v-if="x !== 10" class="spaceUnder">
              <v-text-field
                        maxlength="1"
                        height="20"
                        :disabled="disable((y * 10) + x)"
                        @keyup.native="keyUp($event, x, a[x], a[x + 1], $event.target.value)"
                        class="pa-0 font10" outlined dense></v-text-field>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
    </form>
    <v-container v-if="!show && isFullScreen" >
      <v-card outlined shaped class="mx-auto" max-width="600">
        <v-card-text>
          <p><b>Silahkan baca petunjuk selama 2 menit.</b><br/>
                Kepada saudara semua akan tersedia halaman yang penuh dengan angka, tugas saudara adalah menjumlah.
                Adapun cara menjumlahnya sederhana:
          </p>
          <ol>
            <li>
              Mulailah menjumlah dari baris paling kiri atas.  Jumlahkan angka kesatu dan kedua; dan hasilnya ditulis di dalam kotak yang berada di antara kedua angka tersebut;
            </li>
            <li>
              PERHATIKAN, angka yang ditulis hanya angka satuan, angka puluhan dihilangkan, Sebagai contoh: 5 + 7 = 12, maka yang ditulis 2 (angka di depan yaitu 1 tidak ditulis)
            </li>
            <li>
              Kemudian lanjutkan menjumlah; yaitu angka kedua dan ketiga; dan jumlahnya ditulis juga di dalam kotak, yang ada diantara kedua angka yang sedang dijumlahkan tersebut.
            </li>
            <li>
              Demikian seterusnya.
            </li>
            <li>
              Pengerjaan penjumlahan dilakukan secara berurutan, artinya tidak boleh loncat / melewati satu baris atau angka apabila belum menjawab baris atau angka sebelumnya.
            </li>
            <li>
              Kerjakan secepat cepatnya.
            </li>
            <li>
              Pada saat saat tertentu akan muncul .  .  .  J E D A  .  .  .  maka arahkanlah kembali kursor pada kotak berikutnya untuk diisi angka jawaban yang berikutnya, dan teruskan lah berhitung
            </li>
            <li>
              Ketika sampai pada ujung halaman, maka cursor akan bergeser sendiri; teruslah menghitung; hingga waktu nya habis
            </li>
          </ol>
        </v-card-text>
      </v-card>
    </v-container>
    <v-container v-show="!isFullScreen">
      <v-card  outlined shaped class="mx-auto" max-width="600">
        <v-card-text>Tekan tombol untuk kembali mode Full Screen.<br/>
          <v-btn class="text-capitalize font-weight-regular mt-2"
                @click="fullScreen()"
                color="primary"
                large
                depressed>Full Screen</v-btn>
        </v-card-text>
      </v-card>
    </v-container>
    <v-dialog v-model="dialog" persistent max-width="430">
      <v-card>
        <v-card-title class="headline">
          <p class="mx-auto">Test Pauli</p>
        </v-card-title>
        <v-card-text class="text-left" v-if="!tunggu">
          Test akan di mulai dengan menekan tombol Mulai Test
        </v-card-text>
        <v-card-text class="text-center" v-if="tunggu"> .  .  .  J E D A  .  .  .</v-card-text>
        <v-card-actions v-if="!tunggu">
          <v-btn class="text-capitalize font-weight-regular mt-2"
          @click="startTest()"
          large
          color="primary"
          depressed
          block>Mulai Test</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Header from '../components/Header'
import Timer from '../components/Timer'

export default {
  components: {
    Header,
    Timer
  },
  data () {
    return {
      isFullScreen: false,
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      answers: [],
      show: false,
      idx: 0,
      // totalIsi: 0,
      totalDisable: 0,
      habis: false,
      ulangi: false,
      tunggu: false,
      dialog: false,
      soalPauli: [],
      paulitime: false,
      answersDataAll: [],
      answersData: {
        test_type: 'pauli',
        test_number: 1,
        upper_number: [],
        bottom_number: [],
        answers: []
      }
    }
  },
  created () {
    this.checkSession()
  },
  mounted () {
    this.checkFullScreen()
    this.soalPauli = []
    setTimeout(() => {
      this.answersDataAll = []
      var pauli = []
      for (var i = 0; i < 250; i++) {
        for (var x = 0; x < 11; x++) {
          pauli.push(Math.floor(Math.random() * 9) + 1)
        }
        this.soalPauli.push(pauli)
        pauli = []
      }
    }, 1000)
    setTimeout(() => {
      this.show = true
      this.dialog = true
    }, 120000)
  },
  beforeDestroy () {
    this.soalPauli = []
    this.$root.$refs.timer.stop()
  },
  beforeMount () {
    this.fullScreen()
    window.addEventListener('beforeunload', this.preventNav)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav)
    })
  },
  beforeRouteLeave (to, from, next) {
    this.isFullScreen = false
    const ngetest = localStorage.getItem('instruction')
    if (ngetest !== undefined && ngetest !== null) {
      setTimeout(() => {
        if (!window.confirm('Sesi test akan hilang, tekan "Cancel" atau "Batal", jika ingin tetap melanjutkan test.')) {
          setTimeout(() => {
            this.checkFullScreen()
          }, 100)
          this.fullScreen()
          return
        }
        next()
      }, 100)
    } else {
      next()
    }
  },
  computed: {
    counter () {
      return this.$store.state.timer
    }
  },
  watch: {
    counter (newCount, oldCount) {
      if (newCount === 179 || newCount === -1) {
        console.log(this.$root.$refs.timer)
        this.tunggu = true
        this.dialog = true
        setTimeout(() => {
          const elements = document.getElementsByTagName('input')
          let totalIsi = 0
          Array.prototype.forEach.call(elements, (e) => {
            if (isNaN(e.value) || e.value === undefined || e.value === null || e.value === '' || e.value === ' ') {
              return
            }
            totalIsi++
          })
          let a = 0
          let red = true
          Array.prototype.forEach.call(elements, (e) => {
            a++
            if (a >= totalIsi && red) {
              red = false
              e.style.borderBottom = 'medium solid purple'
              e.style.borderTop = 'medium solid purple'
            }
            if (a === (totalIsi + 1)) {
              e.focus()
            }
          })
          console.log('jumlah input ' + elements.length)
          this.answersData.time = newCount
          this.answersDataAll.push(JSON.stringify(this.answersData))
          this.answersData.test_number += 1
          this.answersData.time = 0
          this.answersData.upper_number = []
          this.answersData.bottom_number = []
          this.answersData.answers = []
          console.log(this.answersDataAll)

          this.tunggu = false
          this.dialog = false

          if (newCount === -1) {
            this.habis = true
            this.paulitime = false
            this.kirimData()
            this.totalDisable = elements.length
          } else {
            this.totalDisable = totalIsi
          }
        }, 2000)
      }
    }
  },
  methods: {
    checkSession () {
      const session = localStorage.getItem('sessionTime')
      const lewat = new Date().getTime() - parseInt(session)
      if (lewat > 172800) {
        localStorage.clear()
        this.$router.push('/')
      }
    },
    kirimData () {
      const jawaban = {
        jawaban_semua: this.answersDataAll
      }
      this.axios.post(this.backendUrl + '/api/simpan-data-jawaban-pauli', jawaban)
        .then(response => {
          this.answersDataAll = []
        }).catch(e => {
          console.log(e)
        })
    },
    disable (idx) {
      return idx < this.totalDisable
    },
    keyUp (event, idx, upper, bottom, answer) {
      if (isNaN(answer) || answer === undefined || answer === null || answer === '' || answer === ' ') {
        event.preventDefault()
        event.currentTarget.value = ''
        return
      }
      this.next(event)
      const answerInt = parseInt(answer)

      this.answersData.upper_number.push(upper)
      this.answersData.bottom_number.push(bottom)
      this.answersData.answers.push(answerInt)
    },
    next (event) {
      var form = event.target.form
      var index = Array.prototype.indexOf.call(form, event.target)
      form.elements[index + 2].focus()
    },
    startTest () {
      this.dialog = false
      this.paulitime = true
      this.$root.$refs.timer.timeSet()
      setTimeout(() => {
        document.getElementsByTagName('input')[0].focus()
      }, 500)
    },
    preventNav (event) {
      this.isFullScreen = false
      const ngetest = localStorage.getItem('instruction')
      if (ngetest !== undefined && ngetest !== null) {
        setTimeout(() => {
          this.checkFullScreen()
          event.preventDefault()
          event.returnValue = ''
          event.originalEvent.returnValue = 'Sesi test akan hilang, tekan "Cancel" atau "Batal", jika ingin tetap melanjutkan test.'
        }, 100)
      }
    },
    checkFullScreen () {
      const windowWidth = window.innerWidth * window.devicePixelRatio
      const windowHeight = window.innerHeight * window.devicePixelRatio
      const screenWidth = window.screen.width
      const screenHeight = window.screen.height
      // console.log(windowWidth / screenWidth)
      // console.log(windowHeight / screenHeight)
      if (((windowWidth / screenWidth) >= 0.95) && ((windowHeight / screenHeight) >= 0.95)) {
        this.isFullScreen = true
        // console.log('Fullscreen')
      } else {
        this.isFullScreen = false
        // console.log('Not Fullscreen')
      }
    },
    fullScreen () {
      const elem = document.documentElement

      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen()
      }

      window.addEventListener('resize', () => {
        setTimeout(() => {
          this.checkFullScreen()
        }, 100)
      })
    }
  }
}

</script>

<style lang="css" scoped>
/deep/ .v-input__slot {
  margin-bottom: 0 !important;
  min-height: 25px !important;
  width: 27px !important;
  padding: 0 8px !important;
}
/deep/ .v-text-field__details {
  display: none !important;
}
tr.spaceUnder>td {
  padding-bottom: 8px;
  padding-top: 10px;
}

.spaceUnder {
  padding-left: 8px
}

.py-1 {
  color: blue !important;

}

.font10 {
  font-size: 11px;
  font-weight: bold;
}

.fixed {
  position: fixed
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.scroll {
  overflow-y: none !important;
  overflow-x: scroll !important;
  margin: 5px;
}

.row {
  flex-wrap: nowrap;
}

.col {
  padding: 5px;
}

</style>
