export default {
  contoh: [
    {
      type: 'fill',
      instruction: 'Ditentukan dua kata.\nCarilah satu perkataan yang meliputi pengertian kedua kata tadi.\nTulislah perkataan itu pada kolom disamping soal.',
      questions: [
        {
          number: 1,
          title: 'Contoh 04',
          question: 'Ayam - Itik',
          answers: {
            hint: 'Perkataan "Burung" dapat meliputi pengertian kedua kata itu.\nMaka jawabannya ialah "Burung".',
            answer: 'Burung',
            message: 'Oleh karena itu, pada kolom contoh 04, harus diisi "Burung".'
          }
        },
        {
          number: 2,
          title: 'Contoh berikutnya',
          question: 'Gaun - Celana',
          answers: {
            hint: 'Pada contoh ini jawabannya ialah : "Pakaian", maka "Pakaian" yang seharusnya diisi.',
            answer: 'Pakaian',
            message: 'Carilah selalu perkataan yang tepat yang dapat meliputi pengertian kedua kata itu.'
          }
        }
      ],
      timer: 60
    }
  ],

  soal: [
    {
      timer: 480,
      questions: [
        {
          number: 61,
          question: 'mawar - melati'
        },
        {
          number: 62,
          question: 'mata - telinga'
        },
        {
          number: 63,
          question: 'gula - intan'
        },
        {
          number: 64,
          question: 'hujan - salju'
        },
        {
          number: 65,
          question: 'pengantar surat - telepon'
        },
        {
          number: 66,
          question: 'kamera - kacamata'
        },
        {
          number: 67,
          question: 'lambung - usus'
        },
        {
          number: 68,
          question: 'banyak - sedikit'
        },
        {
          number: 69,
          question: 'telur - benih'
        },
        {
          number: 70,
          question: 'bendera - lencana'
        },
        {
          number: 71,
          question: 'rumput - gajah'
        },
        {
          number: 72,
          question: 'ember - kantong'
        },
        {
          number: 73,
          question: 'awal - akhir'
        },
        {
          number: 74,
          question: 'kikir - boros'
        },
        {
          number: 75,
          question: 'penawaran - permintaan'
        },
        {
          number: 76,
          question: 'atas - bawah'
        }
      ]
    }
  ]

}
