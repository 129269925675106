export default {
  contoh: [
    {
      type: 'selection',
      instruction: 'Ditentukan lima kata.\nPada 4 dari 5 kata itu terdapat suatu kesamaan.\nCarilah kata yang kelima yang tidak memiliki kesamaan dengan keempat kata itu.',
      questions: [
        {
          number: 1,
          title: 'Contoh 02',
          question: '',
          question_marks: ['Meja', 'Kursi', 'Burung', 'Lemari', 'Tempat tidur'],
          answers: {
            hint: 'Meja, Kursi, Lemari, dan Tempat tidur ialah perabot rumah (meubel).\nc Burung bukan perabot rumah atau tidak memiliki kesamaan dengan keempat itu',
            answer: 'Burung',
            message: 'Oleh karena itu, pada pilihan contoh 02, "Burung" yang harus dipilih.'
          }
        },
        {
          number: 2,
          title: 'Contoh berikutnya',
          question: '',
          question_marks: ['Duduk', 'Berbaring', 'Berdiri', 'Berjalan', 'Berjongkok'],
          answers: {
            hint: 'Pada Duduk, Berbaring, Berdiri, dan Berjongkok, orang dalam keadaan tidak bergerak, sedangkan Berjalan, orang dalam keadaan bergerak\nMaka jawaban yang benar ialah: "Berjalan".',
            answer: 'Berjalan',
            message: 'Oleh karena itu "Berjalan" seharusnya yang dipilih.'
          }
        }
      ],
      timer: 120
    }
  ],
  soal: [
    {
      timer: 360,
      questions: [
        {
          number: 21,
          question: '',
          question_marks: {
            a: 'Lingkaran',
            b: 'Panah',
            c: 'Elips',
            d: 'Busur',
            e: 'Lengkungan'
          }
        },
        {
          number: 22,
          question: '',
          question_marks: {
            a: 'Mengetuk',
            b: 'Memaki',
            c: 'Menjahit',
            d: 'Menggergaji',
            e: 'Memukul'
          }
        },
        {
          number: 23,
          question: '',
          question_marks: {
            a: 'Lebar',
            b: 'Keliling',
            c: 'Luas',
            d: 'Isi',
            e: 'Panjang'
          }
        },
        {
          number: 24,
          question: '',
          question_marks: {
            a: 'Mengikat',
            b: 'Menyatukan',
            c: 'Melepaskan',
            d: 'Mengaitkan',
            e: 'Melekatkan'
          }
        },
        {
          number: 25,
          question: '',
          question_marks: {
            a: 'Arah',
            b: 'Timur',
            c: 'Perjalanan',
            d: 'Tujuan',
            e: 'Selatan'
          }
        },
        {
          number: 26,
          question: '',
          question_marks: {
            a: 'Jarak',
            b: 'Perpisahan',
            c: 'Tugas',
            d: 'Batas',
            e: 'Perceraian'
          }
        },
        {
          number: 27,
          question: '',
          question_marks: {
            a: 'Saringan',
            b: 'Kelambu',
            c: 'Payung',
            d: 'Tapisan',
            e: 'Jala'
          }
        },
        {
          number: 28,
          question: '',
          question_marks: {
            a: 'Putih',
            b: 'Pucat',
            c: 'Buram',
            d: 'Kasar',
            e: 'Berkilauan'
          }
        },
        {
          number: 29,
          question: '',
          question_marks: {
            a: 'Otobis',
            b: 'Pesawat terbang',
            c: 'Sepeda motor',
            d: 'Sepeda',
            e: 'Kapal api'
          }
        },
        {
          number: 30,
          question: '',
          question_marks: {
            a: 'Biola',
            b: 'Seruling',
            c: 'Klarinet',
            d: 'Terompet',
            e: 'Saxophone'
          }
        },
        {
          number: 31,
          question: '',
          question_marks: {
            a: 'Bergelombang',
            b: 'Kasar',
            c: 'berduri',
            d: 'Licin',
            e: 'Lurus'
          }
        },
        {
          number: 32,
          question: '',
          question_marks: {
            a: 'Jam',
            b: 'Kompas',
            c: 'Penunjuk jalan',
            d: 'Bintang pari',
            e: 'Arah'
          }
        },
        {
          number: 33,
          question: '',
          question_marks: {
            a: 'Kebijaksanaan',
            b: 'Pendidikan',
            c: 'Perencanaan',
            d: 'Penempatan',
            e: 'Pengerahan'
          }
        },
        {
          number: 34,
          question: '',
          question_marks: {
            a: 'Bermotor',
            b: 'Berjalan',
            c: 'Berlayar',
            d: 'Bersepeda',
            e: 'Berkuda'
          }
        },
        {
          number: 35,
          question: '',
          question_marks: {
            a: 'Gambar',
            b: 'Lukisan',
            c: 'Potret',
            d: 'Patung',
            e: 'Ukiran'
          }
        },
        {
          number: 36,
          question: '',
          question_marks: {
            a: 'Panjang',
            b: 'Lonjong',
            c: 'Runcing',
            d: 'Bulat',
            e: 'Bersudut'
          }
        },
        {
          number: 37,
          question: '',
          question_marks: {
            a: 'Kunci',
            b: 'Palang pintu',
            c: 'Gerendel',
            d: 'Gunting',
            e: 'Obeng'
          }
        },
        {
          number: 38,
          question: '',
          question_marks: {
            a: 'Jembatan',
            b: 'Batas',
            c: 'Perkawinan',
            d: 'Pagar',
            e: 'Masyarakat'
          }
        },
        {
          number: 39,
          question: '',
          question_marks: {
            a: 'Mengetam',
            b: 'Memahat',
            c: 'Mengasah',
            d: 'Melicinkan',
            e: 'Menggosok'
          }
        },
        {
          number: 40,
          question: '',
          question_marks: {
            a: 'Batu',
            b: 'Baja',
            c: 'Bulu',
            d: 'Karet',
            e: 'Kayu'
          }
        }
      ]
    }
  ]
}
