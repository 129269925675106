/* eslint-disable no-unmodified-loop-condition */
<template>
  <div class="">
    <Header/>
    <v-row class="mt-8">
      <v-col class="pl-12" cols="8">
        <SecondHeader/>
        <QuestionCard v-show="muncul"/>
        <v-card outlined shaped elevation="1" v-show="!muncul">
          <v-card-text>
            Tekan tombol untuk kembali mode Full Screen.<br/>
            <v-btn class="text-capitalize font-weight-regular mt-2"
                  @click="fullScreen()"
                  color="primary"
                  large
                  depressed>Full Screen</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="pr-12">
        <UserPositionCard/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Header from '../components/Header'
import QuestionCard from '../components/QuestionCard'
import UserPositionCard from '../components/UserPositionCard'
import SecondHeader from '../components/SecondHeader'

export default {
  components: {
    Header,
    SecondHeader,
    QuestionCard,
    UserPositionCard
  },
  data () {
    return {
      muncul: true,
      instruction: [
        [true, 120],
        [true, 120],
        [true, 120],
        [true, 120],
        [true, 120],
        [true, 120],
        [true, 120],
        [true, 120],
        [true, 120]
      ]
    }
  },
  created () {
    this.checkSession()
    localStorage.setItem('instruction', JSON.stringify(this.instruction))
    this.$store.commit('instructionReset', this.instruction)
  },
  mounted () {
    this.checkFullScreen()
  },
  beforeMount () {
    this.fullScreen()

    window.addEventListener('beforeunload', this.preventNav)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.preventNav)
    })
  },
  beforeRouteLeave (to, from, next) {
    this.muncul = false
    const ngetest = localStorage.getItem('instruction')
    if (ngetest !== undefined && ngetest !== null) {
      setTimeout(() => {
        if (!window.confirm('Sesi test akan hilang, tekan "Cancel" atau "Batal", jika ingin tetap melanjutkan test.')) {
          setTimeout(() => {
            this.checkFullScreen()
          }, 100)
          this.fullScreen()
          return
        }
        next()
      }, 100)
    } else {
      next()
    }
  },
  methods: {
    checkSession () {
      const session = localStorage.getItem('sessionTime')
      const lewat = new Date().getTime() - parseInt(session)
      if (lewat > 172800) {
        localStorage.clear()
        this.$router.push('/')
      }
    },
    preventNav (event) {
      this.muncul = false
      const ngetest = localStorage.getItem('instruction')
      if (ngetest !== undefined && ngetest !== null) {
        setTimeout(() => {
          this.checkFullScreen()
          event.preventDefault()
          event.returnValue = ''
          event.originalEvent.returnValue = 'Sesi test akan hilang, tekan "Cancel" atau "Batal", jika ingin tetap melanjutkan test.'
        }, 100)
      }
    },
    checkFullScreen () {
      const windowWidth = window.innerWidth * window.devicePixelRatio
      const windowHeight = window.innerHeight * window.devicePixelRatio
      const screenWidth = window.screen.width
      const screenHeight = window.screen.height
      console.log(windowWidth / screenWidth)
      console.log(windowHeight / screenHeight)
      if (((windowWidth / screenWidth) >= 0.95) && ((windowHeight / screenHeight) >= 0.95)) {
        this.muncul = true
        console.log('Fullscreen')
      } else {
        this.muncul = false
        console.log('Not Fullscreen')
      }
    },
    fullScreen () {
      const elem = document.documentElement

      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen()
      }

      window.addEventListener('resize', () => {
        setTimeout(() => {
          this.checkFullScreen()
        }, 100)
      })
    }
  }
}
</script>

<style lang="css" scoped>
/deep/ .v-card__text {
  color: #000 !important;
  font-size: 16px;
}
</style>
