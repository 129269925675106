<template>
  <div class="">
    <div v-for="questions in questions" :key="questions.timer">
      <div v-for="question in questions.questions"
        v-show="current === question.number" class="" :key="question.number">
        <p class="font-weight-bold">Soal {{ question.number }}</p>
        <v-img v-if="reloadI" width="128" :src="baseUrl + '/img/' + question.question + '.png'">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-row v-if="reloadI">
          <v-col v-for="(selection, alphabets, index) in question.question_marks" :key="index">
            <p class="text-center">{{ alphabets }}</p>
            <v-card class="pa-1 text-center" hover outlined round
              :elevation="elevate(answersData, question.number, alphabets)"
              :color="color(answersData, question.number, alphabets)"
              @click.native="answering(question.number, alphabets)">
              <v-img width="120" :src="baseUrl + '/img/' + selection + '.png'">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="reloadI">
          <v-col class="text-right">
            <v-btn class="mt-6"
                  @click="reloadImage()"
                  small
                  color="warning">Muat Ulang
            </v-btn>
            </v-col>
        </v-row>
      </div>
    </div>
    <br>
  </div>
</template>

<script>
export default {
  data () {
    return {
      alphabet: ['A', 'B', 'C', 'D', 'E'],
      answersData: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      reloadI: true
    }
  },
  computed: {
    current () {
      return this.$store.state.current
    },
    testNumber () {
      return this.$store.state.testNumber
    },
    questions () {
      return this.$store.state.questions
    }
  },
  created () {
    this.$root.$refs.imageAnswer = this
  },
  methods: {
    reloadImage () {
      this.reloadI = false
      setTimeout(() => {
        this.reloadI = true
      }, 1250)
    },
    elevate (data, number, mark) {
      let elevate = '0'
      for (var i = 0; i < data.length; i++) {
        if (data[i][0] === number && data[i][1] === mark) {
          elevate = '12'
        }
      }
      return elevate
    },
    color (data, number, mark) {
      let color = ''
      for (var i = 0; i < data.length; i++) {
        if (data[i][0] === number && data[i][1] === mark) {
          color = 'primary'
        }
      }
      return color
    },
    answering (number, answer) {
      let i = 0
      for (; i < this.answersData.length; i++) {
        if (this.answersData[i] !== undefined && this.answersData[i] !== null && this.answersData[i][0] === number) {
          this.answersData[i][1] = answer
          break
        }
      }

      if (i >= this.answersData.length) {
        this.answersData.push([number, answer])
      }

      this.$store.commit('saveAnswer', this.answersData)
      this.$forceUpdate()
    },
    reset () {
      this.$store.commit('resetAnswer')
      this.answersData = []
    }
  }
}
</script>

<style lang="css" scoped>
pre {
   font-family: "Roboto";
   white-space: pre-wrap;
   white-space: -moz-pre-wrap;
   white-space: -pre-wrap;
   white-space: -o-pre-wrap;
   word-wrap: break-word;
}
</style>
