<template>
  <div class="">
    <v-card class="mb-2" outlined shaped elevation="1" v-if="testNumber < 10">
      <v-card-title class="pb-0">
        <p class="mx-auto font-weight-thin text-h4">
        <span v-show="this.$store.state.instructionStatus == true || this.$store.state.rememberStatus == true">PETUNJUK</span>
        <span v-show="this.$store.state.instructionStatus == false">SOAL</span>
        </p>
      </v-card-title>
      <v-card-text v-show="this.type === 'ist'" class="text-center pt-0 pb-0">
        <p v-if="this.$store.state.rememberStatus == true"> </p>
        <p v-if="this.$store.state.rememberStatus == false" class="font-weight-regular text-h6">{{ numbers[0] }} - {{ numbers[1] }}</p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      questions: []
    }
  },
  computed: {
    testNumber () {
      return this.$store.state.testNumber
    },
    numbers () {
      return this.$store.state.numbers
    },
    type () {
      return this.$store.state.testType
    }
  }
}
</script>

<style lang="css" scoped>
</style>
