import ist1 from './soal-ist/ist-1'
import ist2 from './soal-ist/ist-2'
import ist3 from './soal-ist/ist-3'
import ist4 from './soal-ist/ist-4'
import ist5 from './soal-ist/ist-5'
import ist6 from './soal-ist/ist-6'
import ist7 from './soal-ist/ist-7'
import ist8 from './soal-ist/ist-8'
import ist9 from './soal-ist/ist-9'

export default {
  ist: [ist1, ist2, ist3, ist4, ist5, ist6, ist7, ist8, ist9]
}
