<template>
  <div class="">
    <div v-for="questions in questions" :key="questions.timer">
      <div v-for="question in questions.questions" v-show="current == question.number" :key="question.num">
        <p class="font-weight-bold">Soal {{ question.number }}</p>
        <p v-show="testNumber == 5">{{ question.question }}</p>
        <p v-show="testNumber == 6" class="text-center text-h6">{{ question.question }}</p>
        <v-row class="mx-10">
          <v-checkbox v-for="i in 10" :key="i"
            class="mx-4"
            :label="i == 10 ? '0' : i.toString()"
            @change="answers(question.number, i == 10 ? '0' : i.toString(), $event)"
            ></v-checkbox>
        </v-row>
      </div>
    </div>
    <br>
  </div>
</template>

<script>
export default {
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      answersData: [],
      realAnswer: []
    }
  },
  computed: {
    current () {
      return this.$store.state.current
    },
    questions () {
      return this.$store.state.questions
    },
    testNumber () {
      return this.$store.state.testNumber
    }
  },
  created () {
    this.$root.$refs.numericAnswer = this
  },
  methods: {
    ubahUrutan (text, answer, check) {
      const awal = [...text]
      let j = 0
      for (; j < awal.length; j++) {
        if (Number(answer) === Number(awal[j])) {
          if (!check) {
            awal.splice(j, 1)
          }
          break
        } else if (Number(answer) < Number(awal[j]) && Number(answer) !== 0) {
          awal.splice(j, 0, answer)
          break
        }
      }

      if (check && j >= awal.length) {
        awal.push(answer)
      }

      return awal.join('')
    },
    answers (number, answer, event) {
      this.realAnswer = []
      let i = 0
      for (; i < this.answersData.length; i++) {
        if (this.answersData[i] !== undefined && this.answersData[i] !== null && this.answersData[i][0] === number) {
          this.answersData[i][1] = this.ubahUrutan(this.answersData[i][1], answer, event)
          break
        }
      }

      if (i >= this.answersData.length) {
        this.answersData.push([number, answer])
      }

      this.$store.commit('saveAnswer', this.answersData)
    },
    reset () {
      this.$store.commit('resetAnswer')
      this.answersData = []
    }
  }
}
</script>

<style lang="css" scoped>
pre {
   font-family: "Roboto";
   white-space: pre-wrap;
   white-space: -moz-pre-wrap;
   white-space: -pre-wrap;
   white-space: -o-pre-wrap;
   word-wrap: break-word;
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  color: #E0E0E0 !important
}
.v-label {
  color: #000 !important
}
</style>
