<template>
  <div class="">
    <Header />
    <v-container fluid class="px-10 pt-8">
      <v-row v-for="(menu, index) in menus" :key="index">
        <v-col cols="3">
          <v-card @click="testSelect(menu.type, menu.link)" min-width="300" outlined hover tile>
            <v-card-text>
              <v-row>
                <v-col class="pa-0 pl-3">
                  {{ menu.title }}
                </v-col>
                <v-col class="pa-0 text-right pr-3">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Header from '../components/Header'

export default {
  components: {
    Header
  },
  beforeMount () {
    this.closeFullscreen()
    this.checkSession()
  },
  mounted () {
    localStorage.removeItem('instruction')
    this.$store.commit('questionsDataReset')
    this.$store.commit('resetCurrent')
    this.$store.commit('testReset')
    this.$store.commit('instructionDataReset')
  },
  data () {
    return {
      menus: [
        { title: 'TEST 1', type: 'ist', link: '/test1' },
        { title: 'TEST 2', type: 'pauli', link: '/test2' },
        { title: 'TEST 3', type: 'cfit', link: '/test3' },
        { title: 'TEST 4', type: 'survey', link: '/test4' }
      ]
    }
  },
  methods: {
    checkSession () {
      const session = localStorage.getItem('sessionTime')
      const lewat = new Date().getTime() - parseInt(session)
      if (lewat > 172800) {
        localStorage.clear()
        this.$router.push('/')
      }
    },
    closeFullscreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen()
      }
    },
    testSelect (test, link) {
      this.$store.commit('setTest', test)
      localStorage.setItem('type', test)
      this.$router.push(link)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
