export default {
  contoh: [
    {
      type: 'image',
      instruction: 'Pada persoalan berikutnya, setiap soal memperlihatkan sesuatu bentuk tertentu yang terpotong menjadi beberapa bagian.\nCarilah di antara bentuk-bentuk yang ditentukan, bentuk yang dapat dibangun dengan cara menyusun potongan-potongan itu sedemikian rupa, sehingga tidak ada kelebihan sudut atau ruang di antaranya.',
      questions: [
        {
          number: 1,
          title: 'Contoh soal',
          question: '07_contoh_1',
          question_marks: {
            a: '07_contoh_soal_a',
            b: '07_contoh_soal_b',
            c: '07_contoh_soal_c',
            d: '07_contoh_soal_d',
            e: '07_contoh_soal_e'
          },
          answers: {
            hint: 'Jika potongan-potongan pada contoh soal diatas disusun (digabungkan), maka akan menghasilkan bentuk ',
            answer: 'a',
            message: 'Oleh karena itu, pada pilihan contoh ini, pilihan a yang harus dipilih.'
          }
        },
        {
          number: 2,
          title: 'Contoh berikutnya',
          question: '07_contoh_2',
          question_marks: {
            a: '07_contoh_soal_a',
            b: '07_contoh_soal_b',
            c: '07_contoh_soal_c',
            d: '07_contoh_soal_d',
            e: '07_contoh_soal_e'
          },
          answers: {
            hint: 'Jika potongan-potongan pada contoh soal diatas disusun (digabungkan), maka akan menghasilkan bentuk ',
            answer: 'e',
            message: 'Oleh karena itu, pada pilihan contoh ini, pilihan e yang harus dipilih.'
          }
        },
        {
          number: 3,
          title: 'Contoh berikutnya',
          question: '07_contoh_3',
          question_marks: {
            a: '07_contoh_soal_a',
            b: '07_contoh_soal_b',
            c: '07_contoh_soal_c',
            d: '07_contoh_soal_d',
            e: '07_contoh_soal_e'
          },
          answers: {
            hint: 'Jika potongan-potongan pada contoh soal diatas disusun (digabungkan), maka akan menghasilkan bentuk ',
            answer: 'b',
            message: 'Oleh karena itu, pada pilihan contoh ini, pilihan b yang harus dipilih.'
          }
        },
        {
          number: 4,
          title: 'Contoh berikutnya',
          question: '07_contoh_4',
          question_marks: {
            a: '07_contoh_soal_a',
            b: '07_contoh_soal_b',
            c: '07_contoh_soal_c',
            d: '07_contoh_soal_d',
            e: '07_contoh_soal_e'
          },
          answers: {
            hint: 'Jika potongan-potongan pada contoh soal diatas disusun (digabungkan), maka akan menghasilkan bentuk ',
            answer: 'd',
            message: 'Oleh karena itu, pada pilihan contoh ini, pilihan d yang harus dipilih.'
          }
        }
      ],
      timer: 240
    }
  ],

  soal: [
    {
      timer: 420,
      questions: [
        {
          number: 117,
          question: '07_soal_117',
          question_marks: {
            A: '07_soal_1_a',
            B: '07_soal_1_b',
            C: '07_soal_1_c',
            D: '07_soal_1_d',
            E: '07_soal_1_e'
          }
        },
        {
          number: 118,
          question: '07_soal_118',
          question_marks: {
            A: '07_soal_1_a',
            B: '07_soal_1_b',
            C: '07_soal_1_c',
            D: '07_soal_1_d',
            E: '07_soal_1_e'
          }
        },
        {
          number: 119,
          question: '07_soal_119',
          question_marks: {
            A: '07_soal_1_a',
            B: '07_soal_1_b',
            C: '07_soal_1_c',
            D: '07_soal_1_d',
            E: '07_soal_1_e'
          }
        },
        {
          number: 120,
          question: '07_soal_120',
          question_marks: {
            A: '07_soal_1_a',
            B: '07_soal_1_b',
            C: '07_soal_1_c',
            D: '07_soal_1_d',
            E: '07_soal_1_e'
          }
        },
        {
          number: 121,
          question: '07_soal_121',
          question_marks: {
            A: '07_soal_1_a',
            B: '07_soal_1_b',
            C: '07_soal_1_c',
            D: '07_soal_1_d',
            E: '07_soal_1_e'
          }
        },
        {
          number: 122,
          question: '07_soal_122',
          question_marks: {
            A: '07_soal_1_a',
            B: '07_soal_1_b',
            C: '07_soal_1_c',
            D: '07_soal_1_d',
            E: '07_soal_1_e'
          }
        },
        {
          number: 123,
          question: '07_soal_123',
          question_marks: {
            A: '07_soal_1_a',
            B: '07_soal_1_b',
            C: '07_soal_1_c',
            D: '07_soal_1_d',
            E: '07_soal_1_e'
          }
        },
        {
          number: 124,
          question: '07_soal_124',
          question_marks: {
            A: '07_soal_1_a',
            B: '07_soal_1_b',
            C: '07_soal_1_c',
            D: '07_soal_1_d',
            E: '07_soal_1_e'
          }
        },
        {
          number: 125,
          question: '07_soal_125',
          question_marks: {
            A: '07_soal_1_a',
            B: '07_soal_1_b',
            C: '07_soal_1_c',
            D: '07_soal_1_d',
            E: '07_soal_1_e'
          }
        },
        {
          number: 126,
          question: '07_soal_126',
          question_marks: {
            A: '07_soal_1_a',
            B: '07_soal_1_b',
            C: '07_soal_1_c',
            D: '07_soal_1_d',
            E: '07_soal_1_e'
          }
        },
        {
          number: 127,
          question: '07_soal_127',
          question_marks: {
            A: '07_soal_1_a',
            B: '07_soal_1_b',
            C: '07_soal_1_c',
            D: '07_soal_1_d',
            E: '07_soal_1_e'
          }
        },
        {
          number: 128,
          question: '07_soal_128',
          question_marks: {
            A: '07_soal_1_a',
            B: '07_soal_1_b',
            C: '07_soal_1_c',
            D: '07_soal_1_d',
            E: '07_soal_1_e'
          }
        },
        {
          number: 129,
          question: '07_soal_129',
          question_marks: {
            A: '07_soal_2_a',
            B: '07_soal_2_b',
            C: '07_soal_2_c',
            D: '07_soal_2_d',
            E: '07_soal_2_e'
          }
        },
        {
          number: 130,
          question: '07_soal_130',
          question_marks: {
            A: '07_soal_2_a',
            B: '07_soal_2_b',
            C: '07_soal_2_c',
            D: '07_soal_2_d',
            E: '07_soal_2_e'
          }
        },
        {
          number: 131,
          question: '07_soal_131',
          question_marks: {
            A: '07_soal_2_a',
            B: '07_soal_2_b',
            C: '07_soal_2_c',
            D: '07_soal_2_d',
            E: '07_soal_2_e'
          }
        },
        {
          number: 132,
          question: '07_soal_132',
          question_marks: {
            A: '07_soal_2_a',
            B: '07_soal_2_b',
            C: '07_soal_2_c',
            D: '07_soal_2_d',
            E: '07_soal_2_e'
          }
        },
        {
          number: 133,
          question: '07_soal_133',
          question_marks: {
            A: '07_soal_2_a',
            B: '07_soal_2_b',
            C: '07_soal_2_c',
            D: '07_soal_2_d',
            E: '07_soal_2_e'
          }
        },
        {
          number: 134,
          question: '07_soal_134',
          question_marks: {
            A: '07_soal_2_a',
            B: '07_soal_2_b',
            C: '07_soal_2_c',
            D: '07_soal_2_d',
            E: '07_soal_2_e'
          }
        },
        {
          number: 135,
          question: '07_soal_135',
          question_marks: {
            A: '07_soal_2_a',
            B: '07_soal_2_b',
            C: '07_soal_2_c',
            D: '07_soal_2_d',
            E: '07_soal_2_e'
          }
        },
        {
          number: 136,
          question: '07_soal_136',
          question_marks: {
            A: '07_soal_2_a',
            B: '07_soal_2_b',
            C: '07_soal_2_c',
            D: '07_soal_2_d',
            E: '07_soal_2_e'
          }
        }
      ]
    }
  ]

}
