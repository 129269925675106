import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import '@babel/polyfill'
import axios from 'axios'
// import VueCookies from 'vue-cookies'
import VueAxios from 'vue-axios'
import VueToast from 'vue-toast-notification'
// import 'vue-toast-notification/dist/theme-default.css'
import 'vue-toast-notification/dist/theme-sugar.css'
// import AsyncComputed from 'vue-async-computed'

// Vue.use(AsyncComputed)
Vue.use(VueToast)
// Vue.use(VueCookies)
Vue.use(VueAxios, axios)

Vue.config.productionTip = false

axios.defaults.timeout = 180000
axios.interceptors.request.use(
  req => {
    const token = localStorage.getItem('token')
    if (token) {
      req.headers.common.Authorization = token
    }
    return req
  },
  err => {
    return Promise.reject(err)
  }
)

axios.interceptors.response.use(
  res => {
    return res
  },
  err => {
    if (err.response.status > 502) {
      Vue.$toast.error(err.response.data.error)
      localStorage.clear()
      window.location.href = '/'
    } else if (err.response.status > 501) {
      Vue.$toast.warning(err.response.data.error)
    } else if (err.response.status > 500) {
      Vue.$toast.error(err.response.data.error)
    }
    return Promise.reject(err)
  }
)
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
