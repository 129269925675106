<template>
  <div>
    <v-app-bar
      color="#FFF"
      class="px-4 outline-bottom"
      flat
    >
      <v-toolbar-title>Psikotest Online</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn depressed color="primary" class="mr-4">{{ user[0].firstName }} / {{ user[0].tglLahir }}</v-btn>

      <v-btn @click="logout()" icon>
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data () {
    return {
      month: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
      backendUrl: process.env.VUE_APP_BACKEND_URL
    }
  },
  created () {
    if (localStorage.getItem('token') === undefined ||
     localStorage.getItem('token') === null ||
     localStorage.getItem('name') === undefined ||
     localStorage.getItem('name') === null ||
     localStorage.getItem('tglLahir') === undefined ||
     localStorage.getItem('tglLahir') === null) {
      this.$router.push('/')
    }

    const tgl = Number(localStorage.getItem('tglLahir'))
    const date = new Date(tgl)
    const sTgl = ('0' + date.getDate()).slice(-2) +
              ' ' + this.month[date.getMonth()] +
              ' ' + date.getFullYear()
    const user = {
      firstName: localStorage.getItem('name'),
      tglLahir: sTgl
    }

    this.$store.commit('saveUser', user)
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    logout () {
      if (this.$router.history.current.path === '/menu') {
        this.axios.post(this.backendUrl + '/api/auth/logout-selesai-test', this.user)
          .then(response => {
            console.log(response.data)
            localStorage.clear()
            this.$router.push('/')
          }).catch(e => {
            console.log(e.response.data.error)
            this.error = e.response.data.error
          })
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style lang="css" scoped>
.outline-bottom {
  border-bottom: 1px solid #E0E0E0 !important
}
</style>
