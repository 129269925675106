<template>
  <div class="">
    <Header/>
    <v-row class="mt-8">
      <v-col class="pl-12" cols="8">
        <SecondHeader/>
        <CfitQuestion />
      </v-col>
      <v-col class="pr-12">
        <UserPositionCard />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Header from '../components/Header'
import CfitQuestion from '../components/CfitQuestion'
import UserPositionCard from '../components/UserPositionCard'
import SecondHeader from '../components/SecondHeader'

export default {
  components: {
    Header,
    SecondHeader,
    CfitQuestion,
    UserPositionCard
  },
  data () {
    return {
      instruction: [
        [true, 120],
        [true, 120],
        [true, 120],
        [true, 120]
      ]
    }
  },
  created () {
    this.checkSession()
    localStorage.setItem('instruction', JSON.stringify(this.instruction))
    this.$store.commit('instructionReset', this.instruction)
  },
  methods: {
    checkSession () {
      const session = localStorage.getItem('sessionTime')
      const lewat = new Date().getTime() - parseInt(session)
      if (lewat > 172800) {
        localStorage.clear()
        this.$router.push('/')
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
