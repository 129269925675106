<template>
  <div class="">
    <v-card class="mb-2" v-show="time != 0" outlined shaped elevation="1" :loading="loading">
      <v-card-text v-show="endOfLife" class="text-center font-weight-light text-h6">Simpan Data</v-card-text>
      <!-- buat debug -->
      <!-- <v-card-text v-show="timeReset > 0 && !endOfLife" class="text-center font-weight-light text-h4">{{ time }}</v-card-text> -->
      <!-- buat debug -->
      <v-card-text v-show="timeReset > (batas - 1) && currentInstruction[0] == false && !endOfLife" class="text-center font-weight-light text-h4">{{ time }}</v-card-text>
      <v-card-text v-show="currentInstruction[0] == true && this.rememberStatus == true && !endOfLife" class="text-center font-weight-light text-h6">Waktu 3 menit</v-card-text>
      <v-card-text v-show="currentInstruction[0] == true && this.rememberStatus == false && !endOfLife" class="text-center font-weight-light text-h6">Waktu 2 menit</v-card-text>
      <v-card-text v-show="timeReset < batas && currentInstruction[0] == false && !endOfLife" class="text-center font-weight-light text-h6">Waktu 6 menit</v-card-text>
    </v-card>
    <v-card outlined shaped elevation="1">
      <v-card-text>
        <template v-if="this.currentInstruction[0] == true && this.rememberStatus == false && !endOfLife">
          <p class="text-h6 font-weight-regular">Instruksi</p>
          <v-row class="mx-0">
            <v-col cols="2" class="pa-1" v-for="i in this.$store.state.instructionLength" :key="i">
              <v-btn
              class="mx-auto"
              :outlined="i == current"
              :color="i == current ? 'primary' : 'white'"
              @click="move(i+1)"
              fab depressed>{{ i }}</v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-if="this.currentInstruction[0] == false && !endOfLife">
          <p class="text-h6 font-weight-regular">Soal</p>
            <v-row class="mx-0" v-for="question in questions" :key="question.timer">
              <v-col cols="2" class="pa-1" v-for="i in question.questions" :key="i.number">
                <v-btn
                :outlined="i.number == current"
                :_xdisabled="i.number > current"
                :color="i.number == current ? 'primary' : 'white'"
                @click="move(i.number+1)"
                fab depressed>{{ i.number }}</v-btn>
              </v-col>
            </v-row>
        </template>
        <v-btn class="text-capitalize font-weight-regular mt-2" @click="startTest()"
          v-show="currentInstruction[0] == true" large color="primary" depressed block>
          <span v-if="this.rememberStatus">Lanjut Petunjuk</span>
          <span v-if="!this.rememberStatus">Mulai Tes</span>
        </v-btn>
        <v-btn class="text-capitalize font-weight-regular mt-2"
        @click="testNumber >= 9 && test === 'ist' || testNumber === 4 && test === 'cfit' || test === 'survey' ? finish() : nextTest()"
        color="primary"
        large
        depressed
        :disabled="loading"
        v-show="(currentInstruction[0] == false && lanjut) || this.$store.state.testNumber > 9"
        block>{{ this.$store.state.testNumber >= 9 && test === 'ist' || testNumber === 4 && test === 'cfit' && test === 'survey' ? 'Selesai' : 'Lanjut ke kelompok soal selanjutnya' }}</v-btn>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="460">
      <v-card>
        <v-card-title class="headline">Waktu Habis!</v-card-title>
        <v-card-text>{{ this.textHeader }}.</v-card-text>
        <v-card-actions>
          <v-btn class="text-capitalize font-weight-regular mt-2"
          @click="startTest()"
          v-if="currentInstruction[0] == true"
          large
          color="primary"
          depressed
          block>Mulai Tes</v-btn>

          <v-btn class="text-capitalize font-weight-regular mt-2"
          @click="testNumber >= 9 && test === 'ist' || testNumber === 4 && test === 'cfit' || test === 'survey' ? finish() : nextTest()"
          color="primary"
          large
          depressed
          v-if="currentInstruction[0] == false"
          block>{{ this.$store.state.testNumber >= 9 && test === 'ist' || testNumber === 4 && test === 'cfit' || test === 'survey' ? 'Selesai' : 'Lanjut ke kelompok soal selanjutnya' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import dataTest from '../data'

export default {
  data () {
    return {
      loading: false,
      jump: 0,
      time: 0,
      batas: 0,
      currentInstruction: [],
      timeUsed: 0,
      timeReset: 0,
      dialog: false,
      instruction: [],
      timeOption: [],
      questionTime: [],
      interval: '',
      next: 0,
      endOfLife: false,
      textHeader: 'Waktu yang diberikan untuk memahami instruksi telah habis, silahkan mulai tes sekarang',
      baseUrl: process.env.VUE_APP_BASE_URL,
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      answerData: {
        test_type: '',
        test_number: '',
        time: '',
        answer_data: ''
      }
    }
  },
  mounted () {
    this.next = this.testNumber + 1
    this.getData()
    this.instructionUpdate()
    // this.$store.commit('setTime', moment.utc().startOf('day'))
  },
  beforeDestroy () {
    this.endOfLife = false
    clearInterval(this.interval)
  },
  computed: {
    lanjut () {
      return (this.$store.state.current === this.$store.state.numbers[1]) // && (this.answeredData.length >= this.$store.state.numbers[1])
    },
    user () {
      return this.$store.state.user
    },
    duration () {
      return this.$store.state.timer
    },
    current () {
      return this.$store.state.current
    },
    testNumber: {
      get () {
        return this.$store.state.testNumber
      }
    },
    numbers () {
      return this.$store.state.numbers
    },
    questions () {
      return this.$store.state.questions
    },
    instructions () {
      return this.$store.state.instructionData
    },
    rememberStatus () {
      return this.$store.state.rememberStatus
    },
    test () {
      return this.$store.state.testType
    },
    tesType () {
      return this.$store.state.currentTest
    },
    answeredData () {
      return this.$store.state.answersData
    }
  },
  methods: {
    move (i) {
      this.jump = i - 1
      this.$store.commit('move', this.jump)
    },
    instructionUpdate () {
      this.instruction = JSON.parse(localStorage.getItem('instruction'))
      this.currentInstruction = []
      this.currentInstruction.push(this.instruction[this.testNumber - 1])
      this.currentInstruction = this.currentInstruction.flat()
    },
    getRememberData () {
      this.loading = true
      setTimeout(() => {
        const response = dataTest[this.test][this.testNumber - 1].ingat
        this.loading = false
        this.timeOption = response.map(x => {
          return x.timer
        })
        this.timeSet(this.timeOption)
        this.$store.commit('rememberDataUpdate', response)
        this.$store.commit('rememberEnable')
      }, 500)

      // this.axios.get(this.baseUrl + '/json/' + this.test + '/' + this.testNumber + '/remember.json')
      //   .then(response => {
      //     this.loading = false
      //     this.timeOption = this.instructions.map(x => {
      //       return x.timer
      //     })
      //     this.timeSet(this.timeOption)
      //     this.$store.commit('rememberDataUpdate', response.data)
      //     this.$store.commit('rememberEnable')
      //   }).catch(e => {
      //     this.loading = false
      //     console.log(e)
      //   })
      this.$forceUpdate()
    },
    simpanHasilTest () {
      this.timeReset = 0
      if (this.testNumber < 10) {
        console.log('this.current ' + this.current)
        console.log('this.numbers ' + this.numbers)
        console.log('this.questions ' + this.questions)
        const info = 'test.' + this.test + '.' + this.testNumber
        localStorage.setItem(info, JSON.stringify(this.answerData))
        this.selesaiSatuTest()
      }
    },
    kirimHasilTest () {
      this.endOfLife = true
      this.loading = true
      const axioS = []
      const data = []
      this.$toast.open('Menyimpan data ke server')
      for (let i = 1; i < 10; i++) {
        const info = 'test.' + this.test + '.' + i
        data.push(JSON.parse(localStorage.getItem(info)))
      }
      for (let i = 0; i < 9; i++) {
        axioS.push(this.axios.post(this.backendUrl + '/api/simpan-data-jawaban-normal', data[i]))
      }
      this.axios.all(axioS).then(this.axios.spread((test1, test2, test3, test4, test5, test6, test7, test8, test9) => {
        this.loading = false
        localStorage.removeItem('instruction')
        this.$toast.open('Data berhasil disimpan ke server')
        this.$router.push('/menu')
      })).catch(e => {
        this.loading = false
        this.$toast.error('Data gagal disimpan ke server, silahkan ulangi.')
        console.log(e)
      })
    },
    selesaiSatuTest () {
      this.$store.commit('moveTest')
      this.next = this.testNumber
      if (this.testNumber < 10) {
        if (this.tesType === 'selection') {
          this.$root.$refs.answer.reset()
        } else if (this.testType === 'numeric') {
          this.$root.$refs.numericAnswer.reset()
        } else if (this.testType === 'fill') {
          this.$root.$refs.fillAnswer.reset()
        } else if (this.testType === 'image') {
          this.$root.$refs.imageAnswer.reset()
        }
        clearInterval(this.interval)
        if (this.testNumber === 9) {
          this.$store.commit('startRemember')
          this.getRememberData()
        } else {
          this.getData()
        }

        if (this.testNumber === 9 && this.rememberStatus === false) {
          this.textHeader = 'Waktu yang diberikan untuk mengingat sudah habis.'
          this.$store.commit('rememberEnable')
        } else if (this.testNumber === 9 && this.rememberStatus === true) {
          this.$store.commit('rememberDisable')
          this.getData()
        }
        this.instructionUpdate()
      }
    },
    getData () {
      this.loading = true
      this.$store.commit('questionsDataReset')
      this.$store.commit('instructionDataReset')
      this.$store.commit('setTest', localStorage.getItem('type'))

      this.$forceUpdate()

      setTimeout(() => {
        const response = dataTest[this.test][this.testNumber - 1].contoh
        this.loading = false
        this.$store.commit('instructionDataUpdate', response)
        this.$store.commit('numAnswersUpdate', response)
        this.timeOption = response.map(x => {
          return x.timer
        })
        this.timeSet(this.timeOption)
      }, 500)

      setTimeout(() => {
        const response = dataTest[this.test][this.testNumber - 1].soal
        this.$store.commit('questionsDataUpdate', response)
        this.questionTime = response.map(x => {
          return x.timer
        })
        this.batas = this.questionTime - 30
        this.loading = false
      }, 500)
    },
    timeSet (time) {
      this.$store.commit('setTime', moment.utc().startOf('day').add(time[0] / 60, 'minutes'))
      this.time = this.duration.format('mm:ss')
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        console.log(this.time)
        this.$store.commit('setTime', moment(this.duration).subtract(1, 'seconds'))
        this.timeUsed += 1
        this.timeReset += 1
        this.time = this.duration.format('mm:ss')
        if (this.time === '00:00') {
          clearInterval(this.interval)
          this.dialog = true
          if (this.testNumber !== 9) {
            this.$store.commit('rememberDisable')
          }
        }
      }, 1000)
    },
    startTest () {
      this.textHeader = 'Waktu yang diberikan untuk memahami instruksi telah habis, silahkan mulai tes sekarang'
      this.dialog = false
      clearInterval(this.interval)
      this.timeReset = 0
      if (this.testNumber === 9 && this.rememberStatus === true) {
        // eslint-disable-next-line no-debugger
        // debugger
        this.$store.commit('rememberDisable')
        this.getData()
      } else {
        // eslint-disable-next-line no-debugger
        // debugger
        this.textHeader = 'Waktu yang diberikan untuk kelompok soal ini sudah habis, silahkan ke soal berikutnya'
        this.instruction.forEach((item, i) => {
          if (i === this.testNumber - 1) {
            item[0] = false
          }
        })
        this.timeSet(this.questionTime)
        localStorage.setItem('instruction', JSON.stringify(this.instruction))
        this.$store.commit('instructionUpdate', this.instruction)
        this.$store.commit('move', this.numbers[0])
        this.$store.commit('instructionLocalUpdate')
        this.instructionUpdate()
      }
      this.$forceUpdate()
    },
    nextTest () {
      this.dialog = false
      this.answerData = {
        test_type: this.test,
        test_number: this.testNumber,
        time: this.timeUsed,
        answer_data: this.answeredData
      }
      this.simpanHasilTest()
      this.$forceUpdate()
    },
    finish () {
      this.answerData = {
        test_type: this.test,
        test_number: this.testNumber,
        time: this.timeUsed,
        answer_data: this.answeredData
      }
      this.simpanHasilTest()
      clearInterval(this.interval)
      this.kirimHasilTest()
    }
  }
}
</script>

<style lang="css" scoped>
</style>
