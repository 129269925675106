export default {
  contoh: [
    {
      type: 'numeric',
      instruction: 'Persoalan berikutnya ialah soal-soal hitungan.',
      questions: [
        {
          number: 1,
          title: 'Contoh 05',
          question: 'Sebatang pensil harganya 25 rupiah. berapakah harga 3 batang?',
          answers: {
            hint: 'Perhatikan cara menjawab dibawah!\n\nKolom ini terdiri atas angka-angka 1 sampa 9 dan 0.\nUntuk menunjukkan jawaban suatu soal, maka pilihlah angka-angka yang terdapat di dalam jawaban itu.\nKeurutan angka jawaban tidak perlu dihiraukan. \nJawabannya ialah: 75',
            answer: [7, 5],
            message: 'Pada contoh 05 jawabannya ialah 75.\nOleh karena itu, pada kolom dibawah angka 7 dan 5 harus dipilih.'
          }
        },
        {
          number: 2,
          title: 'Contoh lainnya',
          question: 'Dengan sepeda Husin mencapai 15km dalam waktu 1 jam. Berapa km-kah yang dapat ia capai dalam waktu 4 jam?',
          answers: {
            hint: '',
            answer: [6, 0],
            message: 'Maka untuk menunjukkan jawaban itu angka 6 dan 0 seharusnya yang dipilih.'
          }
        }
      ],
      timer: 180
    }
  ],

  soal: [
    {
      timer: 600,
      questions: [
        {
          number: 77,
          question: 'Jika seorang anak memiliki 50 rupiah dan memberikan 15 rupiah, berapa rupiahkah yang masih tinggal padanya?'
        },
        {
          number: 78,
          question: 'Berapa km-kah yang dapat ditempuh oleh kereta api dalam waktu 7 jam, jika kecepatannya adalah 40km/jam?'
        },
        {
          number: 79,
          question: '15 peti buah-buahan  beratnya 250kg dan setiap peti kosong beratnya 3kg, berapakah berat buah-buahan itu?'
        },
        {
          number: 80,
          question: 'Seseorang mempunyai persediaan rummput yang cukup untuk 7 ekor kuda selama 78 hari. Berapa harikah persediaan itu cukup untuk 21 ekor kuda?'
        },
        {
          number: 81,
          question: '3 batang coklat harganya Rp. 5,-. Berapa batangkah yang dapat kita beli dengan RP. 50,-?'
        },
        {
          number: 82,
          question: 'Seseorang dapat berjalan 1,75m dalam waktu 1/4 detik. Berapa meterkah yang dapat ia tempuh dalam waktu 10 detik?'
        },
        {
          number: 83,
          question: 'Jika sebuah batu terletak 15m di sebelah selatan dari sebatang pohon dan pohon itu berada 30m di sebelah selatan dari sebuah rumah, berapa meterkah jarak antara batu dan rumah itu?'
        },
        {
          number: 84,
          question: 'Jika 4½ m bahan sandang harganya Rp. 90,-, berapa rupiahkah harganya 2½ m?'
        },
        {
          number: 85,
          question: '7 orang dapat menyelesaikan sesuatu pekerjaan dalam 6 hari. Berapa orangkah yang diperlukan untuk menyelesaikan pekerjaan itu dalam waktu setengh hari?'
        },
        {
          number: 86,
          question: 'Karena dipanaskan kawat yang panjangnya 48cm akan mengembang menjadi 52cm. Setelah pemanasan, berapakah panjangnya kawat yang berukuran 72cm?'
        },
        {
          number: 87,
          question: 'Suatu pabrik dapat menghasilkan 304 batang pensil dalam waktu 8 jam. Berapa batangkah dihasilkan dalam waktu setengah jam?'
        },
        {
          number: 88,
          question: 'Untuk suatu campuran diperlukan 2 bagian perak dan 3 bagian timah. Berapa gramkah perak yang diperlukan untuk mendapatkan campuran itu yang beratnya 15 gram?'
        },
        {
          number: 89,
          question: 'Untuk setiap Rp. 3,-, yang dimiliki sidin, Hamid memiliki Rp 5,-. Jika mereka bersama mempunyai Rp. 120,- berapa rupiahkah yang dimiliki Hamid?'
        },
        {
          number: 90,
          question: 'Mesin A menenun 60m kain, sedangkan mesin B menenun 40m. Berapa meterkah yang ditenun mesin A, jika mesin B menenun 60m?'
        },
        {
          number: 91,
          question: 'Seseorang membelikan 1/10 dari uangnya untuk perangko dan 4 kali jumlah itu untuk alat tulis. Sisa uangnya masih Rp. 60,-. Berapa rupiahkah uangnya semula?'
        },
        {
          number: 92,
          question: 'Di dalam dua peti terdapat 43 buah piring, Di dalam peti yang satu terdapat 9 buah piring lebih banyak daripada di dalam peti yang lain. Berapa buah piring terdapat di dalam peti yang lebih kecil?'
        },
        {
          number: 93,
          question: 'Suatu lembaran kain yang panjangnya 60cm harus dibagi sedemikian rupa sehingga panjangnya satu bagian adalah 2/3 dari bagian yang lain. Berapakah panjanya bagian yang terpendek?'
        },
        {
          number: 94,
          question: 'Suatu perusahaan mengekspor 3/4 dari hasil produksinya dan menjual 4/5 dari sisa itu di dalam negeri. Berapa persenkah hasil produksi yang masih tertinggal?'
        },
        {
          number: 95,
          question: 'Jika suatu botol berisi anggur hanya 7/8 bagian dan harganya ialah Rp. 84,-, berapakah harga anggur itu jika botol itu hanya terisi 1/2 penuh?'
        },
        {
          number: 96,
          question: 'Di dalam suatu keluarga setiap anak perempuan mempunyai jumlah saudara laki-laki yang sama dengan jumlah saudara perempuan dan setiap anak laki-laki mempunyai dua kali lebih banyak saudara perempuan daripada saudara laki-laki. Berapa anak laki-lakikah yang terdapat di dalam keluarga itu?'
        }
      ]
    }
  ]

}
