export default {
  contoh: [
    {
      type: 'numeric',
      instruction: 'Pada persoalan berikut akan diberikan deret angka.\nPada setiap deret tersusun menurut suatu aturan tertentu dan dapat dilanjutkan menurut aturan itu.\nCarilah untuk setiap deret, angka berikutnya dan pilihlah jawaban saudara pada kolom di bawah soal.',
      questions: [
        {
          number: 1,
          title: 'Contoh 06',
          question: '2    4    6    8    10    12    14 ?',
          answers: {
            hint: 'Pada deret ini angka berikutnya selalu didapat jika angka di depannya ditambah dengan 2.\nMaka jawabannya ialah: 16',
            answer: [1, 6],
            message: 'Oleh karena itu, pada kolom dibawah angka 1 dan 6 harus dipilih.'
          }
        },
        {
          number: 2,
          title: 'Contoh berikutnya',
          question: '9    7    10    8    11    9    12 ?',
          answers: {
            hint: 'Pada deret ini selalu berganti-gantu harus dikurangi dengan 2 dan setelah itu ditambah dengan 3.\nJawaban contoh ini ialah 10, maka dari itu angka 1 dan angka 0 seharusnya yang dipilih.',
            answer: [1, 0],
            message: 'Kadang-kadang pada beberapa soal harus pula dikalikan atau dibagi.'
          }
        }
      ],
      timer: 180
    }
  ],

  soal: [
    {
      timer: 600,
      questions: [
        {
          number: 97,
          question: '6 9 12 15 18 21 24 ?'
        },
        {
          number: 98,
          question: '15 16 18 19 21 22 24 ?'
        },
        {
          number: 99,
          question: '19 18 22 21 25 24 28 ?'
        },
        {
          number: 100,
          question: '16 12 17 13 18 14 19 ?'
        },
        {
          number: 101,
          question: '2 4 8 10 20 22 44 ?'
        },
        {
          number: 102,
          question: '15 13 16 12 17 11 18 ?'
        },
        {
          number: 103,
          question: '25 22 11 33 30 15 45 ?'
        },
        {
          number: 104,
          question: '49 51 54 27 9 11 14 ?'
        },
        {
          number: 105,
          question: '2 3 1 3 4 2 4 ?'
        },
        {
          number: 106,
          question: '19 17 20 16 21 15 22 ?'
        },
        {
          number: 107,
          question: '94 92 46 44 22 20 10 ?'
        },
        {
          number: 108,
          question: '5 8 9 8 11 12 11 ?'
        },
        {
          number: 109,
          question: '12 15 19 23 28 33 39 ?'
        },
        {
          number: 110,
          question: '7 5 10 7 21 17 68 ?'
        },
        {
          number: 111,
          question: '11 15 18 9 13 16 8 ?'
        },
        {
          number: 112,
          question: '3 8 15 24 35 48 63 ?'
        },
        {
          number: 113,
          question: '4 5 7 4 8 13 7 ?'
        },
        {
          number: 114,
          question: '8 5 15 18 6 3 9 ?'
        },
        {
          number: 115,
          question: '15 6 18 10 30 23 69 ?'
        },
        {
          number: 116,
          question: '5 35 28 4 11 77 70 ?'
        }
      ]
    }
  ]

}
