<template>
  <v-app id="inspire">
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="rounded-lg pa-2" outlined>
              <v-toolbar
                color="white"
                flat
              >
                <v-toolbar-title class="mx-auto">Masuk</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form class="pt-4">
                  <v-text-field
                    label="Email"
                    name="email"
                    outlined
                    :error-messages="error"
                    autofocus
                    color="primary"
                    v-model="user.email"
                    type="text"
                  ></v-text-field>

                  <v-text-field
                    label="Kata sandi"
                    name="password"
                    outlined
                    :error="error != '' ? true : false"
                    color="primary"
                    v-model="user.password"
                    type="password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <!-- <v-btn
                  color="primary"
                  text
                  href="/register"
                  class="text-capitalize">Buat Akun</v-btn>
                <v-spacer></v-spacer> -->
                <v-btn
                  color="primary"
                  depressed
                  class="text-capitalize px-8 py-5"
                  @click.prevent="save">Masuk</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'Login',
  props: {
    source: String
  },
  data () {
    return {
      user: {
        email: '',
        password: ''
      },
      error: '',
      backendUrl: process.env.VUE_APP_BACKEND_URL
    }
  },
  created () {
    if (localStorage.getItem('token') !== undefined &&
     localStorage.getItem('token') !== null &&
     localStorage.getItem('name') !== undefined &&
     localStorage.getItem('name') !== null &&
     localStorage.getItem('tglLahir') !== undefined &&
     localStorage.getItem('tglLahir') !== null) {
      if (this.checkSession()) {
        return
      }
      this.$router.push('/menu')
    }
  },
  methods: {
    checkSession () {
      const session = localStorage.getItem('sessionTime')
      const lewat = new Date().getTime() - parseInt(session)
      return (lewat > 172800)
    },
    remove () {
      this.axios.post(this.backendUrl + '/api/auth/logout-selesai-test', this.user)
        .then(response => {
          console.log(response.data)
          localStorage.clear()
          this.$router.push('/')
        }).catch(e => {
          console.log(e.response.data.error)
          this.error = e.response.data.error
        })
    },
    save () {
      this.axios.post(this.backendUrl + '/api/auth/login', this.user)
        .then(response => {
          console.log(response.data)
          // expected response with user profile data and session / token
          // this.$router.push('/menu')
          localStorage.setItem('name', response.data.namaUser)
          localStorage.setItem('tglLahir', response.data.tanggalLahir)
          localStorage.setItem('token', response.data.token)
          localStorage.setItem('sessionTime', new Date().getTime())
          this.$router.push('/menu')
        }).catch(e => {
          console.log(e.response.data.error)
          this.error = e.response.data.error
        })
    }
  }
}
</script>
<style media="screen">
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
    color: #E0E0E0 !important
  }
  .v-label {
    color: #000 !important
  }
</style>
