<template>
  <div class="" style="margin: 3px">
    <v-container>
    <v-card outlined shaped class="mx-auto" max-width="300" >
      <v-card-title class="pb-0">
        <p class="mx-auto font-weight-thin" v-if="lama">Selamat Mengerjakan</p>
        <p class="mx-auto font-weight-thin" v-if="!lama">{{ timeText }}</p>
        <p class="mx-auto font-weight-thin" v-if="habis">Waktu Habis</p>
      </v-card-title>
    </v-card>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      interval: null,
      timeText: '',
      countdown: null,
      lama: false,
      habis: false,
      count: 0
    }
  },
  created () {
    this.$root.$refs.timer = this
  },
  mounted () {
    this.countdown = moment.utc().startOf('day')// .subtract(51, 'minutes') // moment(60, 'minutes')
    this.$store.commit('setTime', 0)
  },
  computed: {
    counter () {
      return this.$store.state.timer
    }
  },
  methods: {
    stop () {
      clearInterval(this.interval)
    },
    timeSet () {
      this.timeText = this.countdown.format('mm:ss')
      this.$store.commit('setTime', 0)
      this.lama = true
      this.habis = false
      this.interval = setInterval(() => {
        this.count += 1
        this.countdown = moment(this.countdown).subtract(1, 'seconds')
        this.timeText = this.countdown.format('mm:ss')
        if (this.timeText === '00:30') {
          this.lama = false
        } else if (this.timeText === '00:00') {
          clearInterval(this.interval)
          this.countdown = moment.utc().startOf('day')// .subtract(51, 'minutes')
          this.$store.commit('setTime', -1)
          this.habis = true
        } else if (this.counter === 180) {
          this.count = 0
          this.$store.commit('setTime', 0)
        } else {
          this.$store.commit('setTime', this.count)
        }
      }, 1000)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
